import React, {FC} from 'react';

import amazonData from '../../images/pricing-screenshots/amazon-data.png';
import amazonSeller from '../../images/pricing-screenshots/amazon-seller.png';
import deeplTranslate from '../../images/pricing-screenshots/deepl-translate.png';
import discoverEmail from '../../images/pricing-screenshots/discover-email.png';
import geoProxy from '../../images/pricing-screenshots/geo-proxy.png';
import googleMaps from '../../images/pricing-screenshots/google-maps.png';
import googleTranslate from '../../images/pricing-screenshots/google-translate.png';
import proxy from '../../images/pricing-screenshots/proxy.png';
// import trafficAnalytics from '../../images/pricing-screenshots/traffic-analytics.png';
import googleSearch from '../../images/pricing-screenshots/google-search.png';

import loudspeaker from '../../images/pricing-screenshots/loudspeaker.png';
import aiPhone from '../../images/pricing-screenshots/ai-phone.png';

import './styles.scss';

const MiddleSection: FC = () => {
  return (
    <div className="container_home">
      {/* flow 9 */}

      <div className="row my-5">
        <div className="col-lg-6 order-2 order-lg-1 text-center d-flex">
          <img className="automation-flows m-auto" src={proxy} alt="proxy" />
        </div>
        <div className="col-lg-6 order-1 order-lg-2 justify-content-center text-lg-left text-center">
          <h3 className="f_size_30 l_height45 f_700 mb_20">Residential proxy</h3>
          <div className="d-lg-flex mb_20">
            <div>
              <p>
                <span className="colored">0.1</span> premium credits per MB
              </p>
              <p>
                Run our scraping recipes via our residential proxy to get a fresh residential IP for every request,
                ideal if you encounter blocked requests.
              </p>
              <p>
                <span className="colored">105</span> premium credits provides
                <span className="colored"> 1,050 </span> MB of traffic bandwidth.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* flow 10      */}

      <div className="row my-5">
        <div className="col-lg-6  justify-content-center text-lg-left text-center">
          <h3 className="f_size_30 l_height45 f_700 mb_20">Residential geo proxy</h3>
          <div className="d-lg-flex mb_20">
            <div>
              <p>
                <span className="colored">0.12</span> premium credits per MB
              </p>
              <p>
                Run our scraping recipes via a country specific residential proxy to get a fresh residential IP for
                every request, ideal if you encounter blocked requests or GEO personalization.
              </p>
              <p>
                <span className="colored">105</span> premium credits provides
                <span className="colored"> 875 </span> MB of traffic bandwidth.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6  text-center d-flex">
          <img className="automation-flows m-auto" src={geoProxy} alt="geo proxy" />
        </div>
      </div>

      {/* flow 1 */}

      <div className="row my-5">
        <div className="col-lg-6 order-2 order-lg-1 text-center d-flex">
          <img className="automation-flows m-auto" src={deeplTranslate} alt="deepl translate" />
        </div>
        <div className="col-lg-6 order-1 order-lg-2 justify-content-center text-lg-left text-center">
          <h3 className="f_size_30 l_height45 f_700 mb_20">Translate via DeepL</h3>
          <div className="d-lg-flex mb_20">
            <div>
              <p>
                <span className="colored">0.0009</span> premium credits per character.
              </p>
              <p>
                Take advantage of the best rated machine translation service right inside your workflow to translate
                your data, content or spreadsheets in bulk to 26 different languages with formal/informal style support.
              </p>
              <p>
                <span className="colored">105</span> premium credits translates <span className="colored">116,655</span>{' '}
                chars (roughly <span className="colored">23,000</span> words).
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* flow 2 */}

      <div className="row my-5">
        <div className="col-lg-6  justify-content-center text-lg-left text-center">
          <h3 className="f_size_30 l_height45 f_700 mb_20">Translate via Google Translate</h3>
          <div className="d-lg-flex mb_20">
            <div>
              <p>
                <span className="colored">0.0006</span> premium credits per character.
              </p>
              <p>
                Take advantage of the largest AI assisted translation service right inside your workflow to translate
                your data, content or spreadsheets in bulk to 108 different languages.
              </p>
              <p>
                <span className="colored">105</span> premium credits translates <span className="colored">175,000</span>{' '}
                chars (roughly <span className="colored">33,600</span> words).
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6  text-center d-flex">
          <img className="automation-flows m-auto" src={googleTranslate} alt="google translate" />
        </div>
      </div>

      {/* flow 3 */}

      <div className="row my-5">
        <div className="col-lg-6 order-2 order-lg-1 text-center d-flex">
          <img className="automation-flows m-auto" src={googleSearch} alt="google search" />
        </div>
        <div className="col-lg-6 order-1 order-lg-2 justify-content-center text-lg-left text-center">
          <h3 className="f_size_30 l_height45 f_700 mb_20">Get access to Google search data</h3>
          <div className="d-lg-flex mb_20">
            <div>
              <p>
                <span className="colored">0.04</span> premium credits per search (returning{' '}
                <span className="colored">100 </span> listings)
              </p>
              <p>
                Tap into Google search results to find prospects in any industry or geographic area, discover
                businesses, affiliates or publishers.
              </p>
              <p>
                Use Google search data inside your workflow or capture search results into an organised spreadsheet in 1
                click.
              </p>

              <p>
                <span className="colored">105 </span>
                premium credits provides <span className="colored">2,625 </span>
                searches, returning up to
                <span className="colored"> 262,500</span> results.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* flow 4 */}

      <div className="row my-5">
        <div className="col-lg-6  justify-content-center text-lg-left text-center">
          <h3 className="f_size_30 l_height45 f_700 mb_20">Get access to Google maps data</h3>
          <div className="d-lg-flex mb_20">
            <div>
              <p>
                <span className="colored">0.04</span> premium credits per search (returning{' '}
                <span className="colored">100 </span> listings)
              </p>
              <p>
                Tap into Google maps to find hot leads in any industry or geographic area including their review
                ratings, contact details, opening hours and GMB data.
              </p>
              <p>
                Use Google maps data inside your workflow or capture maps results into an organised spreadsheet in 1
                click
              </p>
              <p>
                <span className="colored">105 </span>
                premium credits provides <span className="colored">2,625 </span>
                searches, returning up to
                <span className="colored"> 262,500</span> results.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6  text-center d-flex">
          <img className="automation-flows m-auto" src={googleMaps} alt="google maps" />
        </div>
      </div>

      {/* flow 5 */}

      <div className="row my-5">
        <div className="col-lg-6 order-2 order-lg-1 text-center d-flex">
          <img className="automation-flows m-auto" src={discoverEmail} alt="envelope with letter" />
        </div>
        <div className="col-lg-6 order-1 order-lg-2 justify-content-center text-lg-left text-center">
          <h3 className="f_size_30 l_height45 f_700 mb_20">Discover email addresses for any domain</h3>
          <div className="d-lg-flex mb_20">
            <div>
              <p>
                <span className="colored">0.16</span> premium credits per domain search (returning one or more email
                addresses if available)
              </p>
              <p>Find professional email addresses for any organization in seconds at scale from a domain name.</p>
              <p>
                Speed up your outreach by enriching your lead data right inside your Hexomatic workflow or from a
                spreadsheet upload.
              </p>
              <p>
                <span className="colored">105</span> premium credits provides
                <span className="colored"> 656</span> domain email searches.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* flow 6 */}

      {/* <div className="row my-5">
        <div className="col-lg-6  justify-content-center text-lg-left text-center">
          <h3 className="f_size_30 l_height45 f_700 mb_20">
            Traffic analytics for any domain
          </h3>
          <div className="d-lg-flex mb_20">
            <div>
              <p>
                <span className="colored">0.4</span> premium credits per domain
                search
              </p>
              <p>
                Get top level traffic statistics for any domain including
                estimated traffic, traffic source breakdowns, estimated bounce
                rates and top referring websites.
              </p>
              <p>
                Use traffic insights to sort your leads or capture competitive
                data from competitors or publishers right inside your Hexomatic
                workflow or via a list of domains.
              </p>
              <p>
                <span className="colored">105</span> premium credits provides{" "}
                <span className="colored">260</span> domain analytics reports.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6  text-center d-flex">
          <img
            className="automation-flows m-auto"
            src={trafficAnalytics}
            alt="traffic analytics pie chart"
          />
        </div>
      </div> */}

      {/* flow 7 */}

      <div className="row my-5">
        <div className="col-lg-6 order-1 order-lg-1 justify-content-center text-lg-left text-center">
          <h3 className="f_size_30 l_height45  f_700 mb_20">Get access to Amazon data</h3>
          <div className="d-lg-flex mb_20">
            <div>
              <p>
                <span className="colored">0.09</span> premium credits per search (returning{' '}
                <span className="colored">100</span> listings)
              </p>
              <p>
                Perform Amazon product searches for any geography to get detailed search results including product name,
                price, ranking, image and more.
              </p>
              <p>
                Use Amazon data inside your workflow or capture product results into an organised spreadsheet in 1
                click.
              </p>
              <p>
                <span className="colored">105</span> premium credits provides <span className="colored">1,166</span>{' '}
                searches, returning up
                <span className="colored"> 116,550</span> results.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 order-2 order-lg-2 text-center d-flex">
          <img className="automation-flows m-auto" src={amazonData} alt="amazon data" />
        </div>
      </div>

      {/* flow 8 */}

      <div className="row my-5">
        <div className="col-lg-6 order-2 order-lg-1 text-center d-flex">
          <img className="automation-flows m-auto" src={amazonSeller} alt="amazon seller" />
        </div>
        <div className="col-lg-6 order-1 order-lg-2 justify-content-center text-lg-left text-center">
          <h3 className="f_size_30 l_height45 f_700 mb_20">Get access to Amazon product seller data</h3>
          <div className="d-lg-flex mb_20">
            <div>
              <p>
                <span className="colored">0.06</span> premium credits per search (returning{' '}
                <span className="colored">100</span> listings)
              </p>
              <p>Perform Amazon product ASIN searches for any geography to get detailed listing and seller data.</p>
              <p>
                Use Amazon data inside your workflow or capture product results into an organised spreadsheet in 1
                click.
              </p>
              <p>
                <span className="colored">105</span> premium credits provides <span className="colored">1,750</span>{' '}
                searches.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* flow 9 */}

      <div className="row my-5">
        <div className="col-lg-6 order-1 order-lg-1 justify-content-center text-lg-left text-center">
          <h3 className="f_size_30 l_height45  f_700 mb_20">Generate human sounding speech from text</h3>
          <div className="d-lg-flex mb_20">
            <div>
              <p>
                <span className="colored">0.0008</span> premium credits per char
              </p>
              <p>
                Tap into realistic sounding text to speech at scale. Ideal to create voice overs or sound bytes to send
                to prospects at scale.
              </p>
              <p>
                <span className="colored">105</span> premium credits enable you to voice over around
                <span className="colored"> 26,250</span> words.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 order-2 order-lg-2 text-center d-flex">
          <img className="automation-flows m-auto" src={loudspeaker} alt="loudspeaker" />
        </div>
      </div>

      {/* flow 10 */}

      <div className="row my-5">
        <div className="col-lg-6 order-2 order-lg-1 text-center d-flex">
          <img className="automation-flows m-auto" src={aiPhone} alt="ai phone" />
        </div>
        <div className="col-lg-6 order-1 order-lg-2 justify-content-center text-lg-left text-center">
          <h3 className="f_size_30 l_height45 f_700 mb_20">Tap into Vision AI</h3>
          <div className="d-lg-flex mb_20">
            <div>
              <p>
                <span className="colored">0.1353</span> premium credits per image
              </p>
              <p>Tap into AI to intelligently label images via machine learning.</p>

              <p>
                <span className="colored">105</span> premium credits enable you to identify or label
                <span className="colored"> 776</span> images.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiddleSection;
